<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>medical-room-wait</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9,3.75H6.75V1.5A.75.75,0,0,0,6,.75H4.5a.75.75,0,0,0-.75.75V3.75H1.5a.75.75,0,0,0-.75.75V6a.75.75,0,0,0,.75.75H3.75V9a.75.75,0,0,0,.75.75H6A.75.75,0,0,0,6.75,9V6.75H9A.75.75,0,0,0,9.75,6V4.5A.75.75,0,0,0,9,3.75Z"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="14.25" cy="7.125" r="2.625" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.365,12.785A4.49,4.49,0,0,1,12.75,11.25h3a4.49,4.49,0,0,1,3.385,1.535"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="6.75 17.25 6.75 12 14.25 14.25 14.25 23.25 6.75 21 6.75 20.25"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="21.75 17.25 21.75 12 14.25 14.25 14.25 23.25 21.75 21 21.75 20.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M6.75,20.25a1.5,1.5,0,0,1,0-3H7.5a.75.75,0,0,1,.75.75v1.5a.75.75,0,0,1-.75.75Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.75,20.25a1.5,1.5,0,0,0,0-3H21a.75.75,0,0,0-.75.75v1.5a.75.75,0,0,0,.75.75Z"
    />
  </svg>
</template>
